import { FC, useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import styles from "./styles.module.scss";
import { uploadFile } from "../../services/file.service";
import clsx from "clsx";
import { getLinkForUploadImage } from "../../services/auth.service";

import "quill-image-uploader/dist/quill.imageUploader.min.css";
import ImageResize from "@taoqf/quill-image-resize-module";
// @ts-ignore
import ImageUploader from "quill-image-uploader";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);
Quill.register("modules/imageResize", ImageResize);

const ImageAlt = Quill.import("formats/image");

class CustomImageAlt extends ImageAlt {
  static formats(domNode: any) {
    const alt = domNode.getAttribute("alt");
    return { alt: alt || "" };
  }

  format(name: string, value: string) {
    if (name === "alt") {
      this.domNode.setAttribute("alt", value);
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(CustomImageAlt, true);

const saveToServer = async (file: any) => {
  try {
    const resLinkImg = await getLinkForUploadImage(file?.name, "faq");
    let blob = new Blob([file], { type: file.type });
    await uploadFile(blob, resLinkImg?.data?.data?.uploadUrl);
    return resLinkImg?.data?.data?.downloadUrl;
  } catch (error) {
    console.log("error", error);
  }
};

const DEFAULT_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "image",
  "video",
  "color",
  "background",
  "clean",
  "imageBlot",
  "float",
  "width",
  "height",
  "alt",
];

type RichTextEditorProps = {
  initialValue: string;
  setValue: (value: string) => void;
};

const RichTextEditor: FC<RichTextEditorProps> = ({
  initialValue = ``,
  setValue,
}) => {
  const quillRef = useRef<ReactQuill>(null);

  const handleAddAlt = () => {
    const editor = quillRef.current?.getEditor(); // Отримуємо інстанс редактора
    const range = editor?.getSelection(); // Отримуємо поточну позицію курсора

    if (range) {
      const [leaf] = editor?.getLeaf(range.index); // Отримуємо елемент
      if (leaf?.domNode?.tagName === "IMG") {
        const currentAlt = leaf.domNode.getAttribute("alt") || "";
        const newAlt = prompt("Enter alt text for the image:", currentAlt);

        if (newAlt !== null) {
          leaf.domNode.setAttribute("alt", newAlt); // Оновлюємо атрибут alt
        }
      } else {
        alert("Please select an image to add alt text.");
      }
    } else {
      alert("No selection. Please select an image to add alt text.");
    }
  };

  const DEFAULT_MODULES = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],

          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["link", "image", "video"],
          [{ color: [] }, { background: [] }],

          ["clean"],
          ["alt"],
        ],
        handlers: {
          alt: handleAddAlt,
        },
      },
      imageActions: {},
      imageFormats: {},

      imageResize: {
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
      imageUploader: {
        upload: saveToServer,
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <div className={clsx(styles.wrapper)}>
      <ReactQuill
        ref={quillRef}
        modules={{ ...DEFAULT_MODULES }}
        formats={DEFAULT_FORMATS}
        defaultValue={initialValue}
        onChange={(value) => setValue(value)}
      />
    </div>
  );
};

export default RichTextEditor;
