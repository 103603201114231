import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../apis";

export interface FaqArticleContent {
  id: string;
  index: number;
  type: "text" | "accordion";
  title: string;
  image: string;
  html: string;
  isExpanded: boolean;
}

export interface FaqItem {
  id: string;
  parentId: null;
  type: "section" | "article";
  status: null | "draft" | "published";
  title: string;
  image: null | string;
  order: number;
  createdAt: string;
  updatedAt: string;
  children?: FaqItem[];
  content?: FaqArticleContent[];
}

interface TopLevelRequestResponse {
  data: {
    items: FaqItem[];
  };
  success: boolean;
}

interface Response {
  success: boolean;
}

const getTopLevelFaq = async () => {
  return (await axiosInstance.get<TopLevelRequestResponse>("admin/faq")).data;
};

export const useGetTopLevelFaq = () => {
  return useQuery({
    queryKey: ["top-level-faq"],
    queryFn: async () => (await getTopLevelFaq()).data.items,
  });
};

interface CreateFaqItemRequest {
  type: "section" | "article";
  status?: "draft" | "published";
  title: string;
  image: string;
  parentId: string | undefined;
  content?: any[];
}

const createFaqItem = async (data: CreateFaqItemRequest) => {
  return (await axiosInstance.post<Response>("admin/faq", data)).data;
};

export const useCreateTopLevelFaq = () => {
  return useMutation({
    mutationFn: createFaqItem,
  });
};

interface FaqItemRequestResponse {
  data: {
    item: FaqItem;
  };
  success: boolean;
}

const getFaqItem = async (id: string) => {
  return (await axiosInstance.get<FaqItemRequestResponse>(`admin/faq/${id}`))
    .data;
};

export const useGetFaqItem = (id: string | null) => {
  return useQuery({
    queryKey: ["faq-item", id],
    queryFn: id ? async () => (await getFaqItem(id)).data.item : undefined,
    enabled: !!id,
  });
};

interface UpdateFaqItemRequest {
  id: string;
  parentId?: string;
  status?: "draft" | "published";
  title?: string;
  image?: string;
  content?: any[];
}

const updateFaqItem = async (data: UpdateFaqItemRequest) => {
  return (await axiosInstance.put<Response>("admin/faq", data)).data;
};

export const useUpdateTopLevelFaq = () => {
  return useMutation({
    mutationFn: updateFaqItem,
  });
};

const deleteTopLevelFaqSection = async (id: string) => {
  return (await axiosInstance.delete(`admin/faq/${id}`)).data;
};

export const useDeleteTopLevelFaqSection = () => {
  return useMutation({
    mutationFn: deleteTopLevelFaqSection,
  });
};

interface ChangeOrderFaqItemsRequest {
  parentId?: string;
  ordering: string[];
}

const changeOrderFaqItems = async (body: ChangeOrderFaqItemsRequest) => {
  return (await axiosInstance.post(`admin/faq/order`, body)).data;
};

export const useChangeOrderFaqItems = () => {
  return useMutation({
    mutationFn: changeOrderFaqItems,
  });
};
