import { FC, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowDownIcon } from "../../assets";

type Props = {
  title: string;
  content: string;
  icon?: string;
};

const FaqAccordionPreview: FC<Props> = ({ title, content, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.accordion}>
        {icon ? (
          <div className={styles.iconContainer}>
            <img src={icon} alt="icon" />
          </div>
        ) : null}

        <span>{title}</span>

        <div
          onClick={() => setIsOpen(!isOpen)}
          className={clsx(styles.arrowContainer, {
            [styles.arrowContainerOpen]: isOpen,
          })}
        >
          <ArrowDownIcon />
        </div>
      </div>

      {isOpen ? (
        <div className={"ql-content"}>
          <div
            className={styles.descriptionWrapper}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FaqAccordionPreview;
