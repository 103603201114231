import { ChangeEvent, FC, useRef } from "react";
import { ArrowDownIcon, BarsMenuIcon, TrashIcon } from "../../assets";
import styles from "./styles.module.scss";
import Input from "../Input";
import RichTextEditor from "../RichTextEditor";
import clsx from "clsx";
import { Draggable } from "react-beautiful-dnd";

type FaqAccordingProps = {
  type: "accordion" | "text";
  image: string;
  title: string;
  onChangeTitle: (title: string, id: string) => void;
  html: string;
  index: number;
  onChangeContent: (content: string, id: string) => void;
  onChangeImage: (
    image: {
      imageFile: ArrayBuffer | null | undefined | string | any;
      imageFileName: string;
      image: string;
    },
    id: string
  ) => void;
  id: string;
  isExpanded: boolean;
  onDelete: (id: string) => void;
  onToggleExpanded: (index: number) => void;
};

const FaqAccordion: FC<FaqAccordingProps> = ({
  type,
  title,
  html,
  image,
  index,
  onChangeTitle,
  onChangeContent,
  onChangeImage,
  onDelete,
  isExpanded,
  id,
  onToggleExpanded,
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const showOpenFileDialog = () => {
    inputFile.current?.click();
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      let file = e.target.files[0];
      onChangeImage(
        {
          image: URL.createObjectURL(e.target.files[0]),
          imageFile: file,
          imageFileName: file?.name,
        },
        id
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(e.target.value, id);
  };

  return (
    <div className={styles.wrapper}>
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <section
              className={clsx(styles.accordingInfo, {
                [styles.accordingInfoOpened]: isExpanded,
              })}
            >
              <BarsMenuIcon className={styles.menuIcon} />

              {type === "accordion" ? (
                <>
                  <div
                    onClick={showOpenFileDialog}
                    className={styles.selectIconButton}
                  >
                    {image ? (
                      <img src={image} className={styles.image} alt="image" />
                    ) : (
                      <span>no icon</span>
                    )}
                    {image ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onChangeImage(
                            {
                              image: "",
                              imageFile: undefined,
                              imageFileName: "",
                            },
                            id
                          );
                        }}
                        className={styles.deleteIconContainer}
                      >
                        <TrashIcon className={styles.deleteIcon} />
                      </div>
                    ) : null}
                  </div>

                  <input
                    style={{ display: "none" }}
                    ref={inputFile}
                    type={"file"}
                    accept="image/*"
                    onChange={onSelectFile}
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <Input
                    value={title}
                    onChange={onChangeName}
                    placeholder="Title"
                    wrapperStyles={styles.inputWrapper}
                  />
                </>
              ) : (
                <span className={styles.title}>{title}</span>
              )}
              <div
                onClick={() => onDelete(id)}
                className={styles.deleteIconContainer}
              >
                <TrashIcon className={styles.deleteIcon} />
              </div>

              <div
                onClick={() => onToggleExpanded(index)}
                className={styles.arrowDownIconContainer}
              >
                <ArrowDownIcon
                  className={clsx(styles.arrowDownIcon, {
                    [styles.arrowDownIconOpened]: isExpanded,
                  })}
                />
              </div>
            </section>
          </div>
        )}
      </Draggable>

      {isExpanded ? (
        <RichTextEditor
          initialValue={html}
          setValue={(value) => onChangeContent(value, id)}
        />
      ) : null}
    </div>
  );
};

export default FaqAccordion;
